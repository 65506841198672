import React, { createContext, useContext, useEffect, useState } from "react";
import QRCode from "qrcode";

const Crypto = createContext();

const CryptoContext = ({ children }) => {
  const [currency, setCurrency] = useState("USD");
  const [symbol, setSymbol] = useState("$");
  const [showQr, setShowQr] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [src, setSrc] = React.useState("");

  const generate = () => {
    QRCode.toDataURL("https://web-cryptocore-online.com").then(setSrc);
  };

  useEffect(() => {
    if (currency === "USD") setSymbol("$");
    else if (currency === "USD") setSymbol("$");
  }, [currency]);

  return (
    <Crypto.Provider value={{showQr,setShowQr, modal, setModal, src, setSrc, generate, currency, setCurrency, symbol }}>
      {children}
    </Crypto.Provider>
  );
};

export default CryptoContext;

export const CryptoState = () => {
  return useContext(Crypto);
};
