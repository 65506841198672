export const walletTypeOption =[
    {
        walletValue:"metamask",
        label: "Metamask"
    },
    {
        walletValue:"trustwallet",
        label: "Trust Wallet"
    },
    {
        walletValue:"coinbase",
        label: "Coinbase"
    },
    {
        walletValue:"Keplrwallet",
        label: "Keplr Wallet"
    },
    {
        walletValue:"safepal",
        label: "Safepal"
    },
    {
        walletValue:"tronlink",
        label: "Tronlink"
    },
    {
        walletValue:"defichainwallet",
        label: "Defichain Wallet"
    },
    {
        walletValue:"dfxdefichain",
        label: "DFX Defichain"
    },
    {
        walletValue:"solana",
        label: "Solana"
    },
    {
        walletValue:"blockchain",
        label: "Blockchain"
    },
    {
        walletValue:"lobstrwallet",
        label: "Lobstr Wallet"
    },
    {
        walletValue:"yoroiwallet",
        label: "Yoroi Wallet"
    },

    {
        walletValue:"coinomi",
        label: "Coinomi"
    },
    {
        walletValue:"Edgewallet",
        label: "Edge Wallet"
    },
    {
        walletValue:"solarwallet",
        label: "Solar Wallet"
    }, {
        walletValue:"peraalgowallet",
        label: "Pera Algo Wallet"
    },
    {
        walletValue:"senderwallet",
        label: "Sender Wallet"
    },
    {
        walletValue:"xcelpaywallet",
        label: "Xcel Pay Wallet"
    },
    {
        walletValue:"anchorwallet",
        label: "Anchor Wallet"
    },
    {
        walletValue:"onto",
        label: "ONTO"
    },
    {
        walletValue:"trezor",
        label: "Trezor"
    },
    {
        walletValue:"zengo",
        label: "Zengo"
    },
    {
        walletValue:"atomicwallet",
        label: "Atomic Wallet"
    },
    {
        walletValue:"1inchwallet",
        label: "1inch Wallet"
    },
    {
        walletValue:"bitpay",
        label: "Bitpay"
    },
    {
        walletValue:"electrum",
        label: "Electrum"
    },
    {
        walletValue:"Bitconcore",
        label: "Bitcoin Core"
    },
    {
        walletValue:"alphaWallet",
        label: "AlphaWallet"
    },
    {
        walletValue:"bestwallet",
        label: "Best Wallet"
    },
    {
        walletValue:"Sender Wallet",
        label: "Sender Wallet"
    },
    {
        walletValue:"Xcel Pay Wallet",
        label: "Xcel Pay Wallet"
    },
    {
        walletValue:"Keplr Wallet",
        label: "Keplr Wallet"
    },
    {
        walletValue:" Safepal Wallet",
        label: "Safepal Wallet"
    },
    {
        walletValue:" tronlink Wallet",
        label: "tronlink Wallet"
    },
    {
        walletValue:" Lobstr Wallet",
        label: "Lobstr Wallet"
    },
    {
        walletValue:" Yoroi Wallet",
        label: "Yoroi Wallet"
    },
    {
        walletValue:" Coinomi Wallet",
        label: "Coinomi Wallet"
    },
    {
        walletValue:" Edge Wallet",
        label: "Edge Wallet"
    },
    {
        walletValue:" Edge Wallet",
        label: "Edge Wallet"
    },
    {
        walletValue:" Solar Wallet",
        label: "Solar Wallet"
    },
    {
        walletValue:" Pera Algo Wallet",
        label: "Pera Algo Wallet"
    },
    {
        walletValue:" Sender Wallet",
        label: "Sender Wallet"
    },
    {
        walletValue:" Anchor Wallet",
        label: "Anchor Wallet"
    },
    {
        walletValue:" ONTO",
        label: "ONTO"
    },
    {
        walletValue:" Rainbow Wallet",
        label: "Rainbow Wallet"
    },
    {
        walletValue:" Agent Wallet",
        label: "Agent Wallet"
    },
    {
        walletValue:" CYBAVO Wallet",
        label: "CYBAVO Wallet"
    },
    {
        walletValue:" GrindPlus Wallet",
        label: "GrindPlus Wallet"
    },
    {
        walletValue:" imToken Wallet",
        label: "imToken Wallet"
    },
    {
        walletValue:" Pillar Wallet",
        label: "Pillar Wallet"
    },
    {
        walletValue:" Gnosis safe Multisi Wallet",
        label: "Gnosis safe Multisi Wallet"
    },
    {
        walletValue:" Curve Finance",
        label: "Curve Finance"
    },
    {
        walletValue:" TokenPocket",
        label: "TokenPocket"
    },
    {
        walletValue:"MathWallet",
        label: "MathWallet"
    },
    {
        walletValue:" BitPay",
        label: "BitPay"
    },
    {
        walletValue:"Legder Live",
        label: "Legder Live"
    },
    {
        walletValue:"Walleth",
        label: "Wallet"
    },
    {
        walletValue:"Authereum",
        label: "Authereum"
    },
    {
        walletValue:"Dharma",
        label: "Dharma"
    },
    {
        walletValue:"Dharma",
        label: "Dharma"
    },
    {
        walletValue:" 1inch Walleth",
        label: "1inch Wallet"
    },
    {
        walletValue:" Huobi Walleth",
        label: "Huobi Wallet"
    },
    {
        walletValue:"Eidoo",
        label: "Eidoo"
    },
    {
        walletValue:"MYKEY",
        label: "MYKEY"
    },
    {
        walletValue:"TrustVault",
        label: "TrustVault"
    },
    {
        walletValue:"Curve Finanace",
        label: "Curve Finanace"
    },
    {
        walletValue:"Curve Finance",
        label: "Curve Finance"
    },
    {
        walletValue:"TockenPocket",
        label: "TockenPocket"
    },
    {
        walletValue:"Atomic",
        label: "Atomic"
    },
    {
        walletValue:"Coin98",
        label: "Coin98"
    },
    {
        walletValue:"zkSync",
        label: "zkSync"
    },
    {
        walletValue:"Shell Exchange",
        label: "Shell Exchange"
    },
    {
        walletValue:"Index Coop",
        label: "Index Coop"
    },
    {
        walletValue:"Swapx",
        label: "Swapx"
    },
    {
        walletValue:"StarName",
        label: "StarName"
    },
    {
        walletValue:"Space",
        label: "Space"
    },
    {
        walletValue:"Maskbook",
        label: "Maskbook"
    },
    {
        walletValue:"Snapshot",
        label: "Snapshot"
    },
    {
        walletValue:"Ankr",
        label: "Ankr"
    },
    {
        walletValue:"DODO",
        label: "DODO"
    },
    {
        walletValue:"zLOT",
        label: "zLOT"
    },
    {
        walletValue:"Tokamak Network",
        label: "Tokamak Network"
    },
    {
        walletValue:"Actus Protocol",
        label: "Actus Protocol"
    },
    {
        walletValue:"AstroTools.io ",
        label: "AstroTools.io"
    },
    {
        walletValue:"Rubic Exchange",
        label: "Rubic Exchange"
    },
    {
        walletValue:"Torus Wallet",
        label: "Torus Wallet"
    },
    {
        walletValue:"Oceanprotocol",
        label: "Oceanprotocol"
    },
    {
        walletValue:"Filecone Wallet",
        label: "Filecone Wallet"
    },
    {
        walletValue:"Falconswap",
        label: "Falconswap"
    },
    {
        walletValue:"Bancor Wallet",
        label: "Bancor Wallet"
    },
    {
        walletValue:"Harvest Finance",
        label: "Harvest Finance"
    },
    {
        walletValue:"OctoFi",
        label: "OctoFi"
    },
    {
        walletValue:"Mushroom Finance",
        label: "Mushroom Finance"
    },
    {
        walletValue:"Bitfrost Finance",
        label: "Bitfrost Finance"
    },
    {
        walletValue:"Orchid Protocol",
        label: "Orchid Protocol"
    },
    {
        walletValue:"Datamine Network",
        label: "Datamine Network"
    },
    {
        walletValue:"Streamr",
        label: "Streamr"
    },
    {
        walletValue:"DappRadar",
        label: "DappRadar"
    },
    {
        walletValue:"SuperRare",
        label: "SuperRare"
    },
    {
        walletValue:"ParaSwap",
        label: "ParaSwap"
    },
    {
        walletValue:"HoneySwap",
        label: "HoneySwap"
    },
    {
        walletValue:"Fleek",
        label: "Fleek"
    },
    {
        walletValue:"CoolWallet",
        label: "CoolWallet"
    },
    {
        walletValue:"Alice",
        label: "Alice"
    },
    {
        walletValue:"Alpha Wallet",
        label: "Alpha Wallet"
    },
    {
        walletValue:"Zelcore",
        label: "Zelcore"
    },
    {
        walletValue:"Phantom Wallet",
        label: "Phantom Wallet"
    },
    {
        walletValue:"Solflare Wallet",
        label: "Solflare Wallet"
    },
    {
        walletValue:"Sollet Wallet",
        label: "Sollet Wallet"
    },
    {
        walletValue:"D'CENT Wallet",
        label: "D'CENT Wallet"
    },
    {
        walletValue:"Tokenary",
        label: "Tokenary"
    },
    {
        walletValue:"Spatium",
        label: "Spatium"
    },
    {
        walletValue:"Infinito",
        label: "Infinito"
    },
    {
        walletValue:"Wallet.io",
        label: "Wallet.io"
    },
    {
        walletValue:"Infinity Wallet",
        label: "Infinity Wallet"
    },
    {
        walletValue:"Ownbit",
        label: "Ownbit"
    },
    {
        walletValue:"EasyPocket",
        label: "EasyPocket"
    },
    {
        walletValue:"Bridge Wallet",
        label: "Bridge Wallet"
    },
    {
        walletValue:"Unstoppable Wallet",
        label: "Unstoppable Wallet"
    },
    {
        walletValue:"PEAKDEFI Wallet",
        label: "PEAKDEFI Wallet"
    },
    {
        walletValue:"BitKeep",
        label: "BitKeep "
    },
    {
        walletValue:"ViaWallet",
        label: "ViaWallet"
    },
    {
        walletValue:"SparkPoint",
        label: "SparkPoint"
    },
    {
        walletValue:"HaloDefi Wallet",
        label: "HaloDefi Wallet"
    },
    {
        walletValue:"Dok Wallet",
        label: "Dok Wallet"
    },
    {
        walletValue:"Midas Wallet",
        label: "Midas Wallet"
    },
    {
        walletValue:"Ellipal",
        label: "Ellipal"
    },
    {
        walletValue:"KEYRING PRO",
        label: "KEYRING PRO"
    },
    {
        walletValue:"Aktoinariat",
        label: "Aktoinariat"
    },
    {
        walletValue:"Talken Wallet",
        label: "Talken Wallet"
    },
    {
        walletValue:"XinFin XDC Network",
        label: "XinFin XDC Network"
    },
    {
        walletValue:"Tongue Wallet",
        label: "Tongue Wallet"
    },
    {
        walletValue:"Binance Dex",
        label: "Binance Dex"
    },
    {
        walletValue:"RWallet",
        label: "RWallet"
    },
    {
        walletValue:"PlasmaPay",
        label: "PlasmaPay"
    },
    {
        walletValue:"03Wallet",
        label: "03Wallet"
    },
    {
        walletValue:"HashKey Me",
        label: "HashKey Me"
    },
    {
        walletValue:"Jade Wallet",
        label: "Jade Wallet"
    },
    {
        walletValue:"Guarda Wallet",
        label: "Guarda Wallet"
    },
    {
        walletValue:"Defiant",
        label: "Defiant"
    },
    {
        walletValue:"Trustee Wallet",
        label: "Trustee Wallet"
    },
    {
        walletValue:"MEET.ONE",
        label: "MEET.ONE"
    },
    {
        walletValue:"MoriX Wallet",
        label: "MoriX Wallet"
    },
    {
        walletValue:"Binance Smartch Chain",
        label: "Binance Smartch Chain"
    },
    {
        walletValue:"MoriX Wallet",
        label: "MoriX Wallet"
    },
    {
        walletValue:"Etherscan",
        label: "Etherscan"
    },
    {
        walletValue:"Lido",
        label: "Lido"
    },
    {
        walletValue:"TERRAstation",
        label: "TERRAstation"
    },
    {
        walletValue:"OpenSea",
        label: "OpenSea"
    },
     {
        walletValue:"Zapper",
        label: "Zapper"
    },
    {
        walletValue:"Aave",
        label: "Aave"
    },
    {
        walletValue:"Zerion",
        label: "Zerion"
    },
    {
        walletValue:"LocalCryptos",
        label: "LocalCryptos"
    },
    {
        walletValue:"DeBank",
        label: "DeBank"
    },
    {
        walletValue:"Unstoppable Domains",
        label: "Unstoppable Domains"
    },
    {
        walletValue:"Rarible",
        label: "Rarible"
    },
    {
        walletValue:"Yearn",
        label: "Yearn"
    },
    {
        walletValue:"Adex Network",
        label: "Adex Network"
    },
    {
        walletValue:"MyCrypto",
        label: "MyCrypto"
    },
    {
        walletValue:"Oasis App",
        label: "Oasis App"
    },
    {
        walletValue:"TokenSets",
        label: "TokenSets"
    },
    {
        walletValue:"MANTRA DAO",
        label: "MANTRA DAO"
    },
    {
        walletValue:"Matcha",
        label: "Matcha"
    },
    {
        walletValue:"KyberSwap",
        label: "KyberSwap"
    },
    {
        walletValue:"Defi Portfolio Tracker",
        label: "Defi Portfolio Tracker"
    },   
    {
        walletValue:"Loopring",
        label: "Loopring"
    },
    {
        walletValue:"InstaDapp",
        label: "InstaDapp"
    },
    {
        walletValue:"Matic Network",
        label: "Matic Network"
    },
    {
        walletValue:"3Box",
        label: "3Box"
    },
    {
        walletValue:"DDEX",
        label: "DDEX"
    },
    {
        walletValue:"Bamboo Relay",
        label: "Bamboo Relay"
    },
    {
        walletValue:"Synthetix",
        label: "Synthetix"
    },
    {
        walletValue:"Dex.blue",
        label: "Dex.blue"
    },
    {
        walletValue:"PoolTogether",
        label: "PoolTogether"
    },
    {
        walletValue:"DEX.AG",
        label: "DEX.AG"
    },
    {
        walletValue:"Gelato",
        label: "Gelato"
    },
    {
        walletValue:"ENS DOMAINS",
        label: "ENS DOMAINS"
    },
    {
        walletValue:"Idle Finance",
        label: "Idle Finance"
    },
    {
        walletValue:"iearn.finance",
        label: "iearn.finance"
    },
    {
        walletValue:"Synthetix",
        label: "Synthetix"
    },
    {
        walletValue:"RCN Finanace",
        label: "RCN Finanace"
    },
    {
        walletValue:"Hegic",
        label: "Hegic"
    },
    {
        walletValue:"Kickback",
        label: "Kickback"
    },
    {
        walletValue:"88mph",
        label: "88mph"
    },
    {
        walletValue:"Mooni",
        label: "Mooni"
    },
    {
        walletValue:"Clovers",
        label: "Clovers"
    },
    {
        walletValue:"Mintbase",
        label: "Mintbase"
    },
    {
        walletValue:"DAOstack",
        label: "DAOstack"
    },
    {
        walletValue:"KnownOrigin",
        label: "KnownOrigin"
    },
    {
        walletValue:"MyEtherWallet",
        label: "MyEtherWallet "
    },
    {
        walletValue:"Affogato",
        label: "Affogato"
    },
    {
        walletValue:"StablePay",
        label: "StablePay"
    },
    {
        walletValue:"Defi Saver",
        label: "Defi Saver"
    },
    {
        walletValue:"Synthetix",
        label: "Synthetix"
    },
    {
        walletValue:"Loom Network",
        label: "Loom Network"
    },
    {
        walletValue:"ThorChain",
        label: "ThorChain"
    },
    {
        walletValue:"BulkSender",
        label: "BulkSender"
    },
    {
        walletValue:"Heroes of Ether",
        label: "Heroes of Ether"
    },
    {
        walletValue:"Betoken",
        label: "Betoken"
    },
    
    {
        walletValue:"Linkdrop",
        label: "Linkdrop"
    },
    
    {
        walletValue:"HelioWallet",
        label: "HelioWallet"
    },
    
    {
        walletValue:"Escaroo",
        label: "Escaroo"
    },
    
    {
        walletValue:"Mesa",
        label: "Mesa"
    },
    
    {
        walletValue:"MCDEX",
        label: "MCDEX"
    },
    {
        walletValue:"Furucombo",
        label: "Furucombo"
    },
    {
        walletValue:"Melon",
        label: "Melon"
    },
    {
        walletValue:"Chainsfr",
        label: "Chainsfr"
    },
    {
        walletValue:"AKA3Email Alias",
        label: "AKA3Email Alias"
    },
    {
        walletValue:"2Key",
        label: "2Key"
    },
    {
        walletValue:"SunshiSwap",
        label: "SunshiSwap"
    },
    {
        walletValue:"MCDEX",
        label: "MCDEX"
    },
    {
        walletValue:"Unagii",
        label: "Unagii"
    },
    {
        walletValue:"Cream Finance",
        label: "Cream Finance"
    },

    

    

]

///Card DATA

export const cardData = [
    {
        issueDescription: 'Migration Issues',
        instruction: "Click here for migration related issues",
        issueIcon: "./images/migration.png"
    },
    {
        issueDescription: 'Validate Wallet',
        instruction: "Click here for validation issues",
        issueIcon: "./images/wallet.png"
    },
    {
        issueDescription: 'General Issues',
        instruction: "Click here for general wallet issues",
        issueIcon: "./images/issues.png"
    },
    {
        issueDescription: 'Rectification',
        instruction: "Click here for rectification related issues",
        issueIcon: "./images/bank.png"
    },
    {
        issueDescription: 'Gas Fees',
        instruction: "Click here for gas fee related issues",
        issueIcon: "./images/claim.png"
    },
    {
        issueDescription: 'Claim Reward',
        instruction: "Click here fro claim reward issues",
        issueIcon: "./images/coin.png"
    },
    {
        issueDescription: 'Deposits/Withdrawals',
        instruction: "Click here for deposits/withdrawal issues",
        issueIcon: "./images/bank.png"
    },
    {
        issueDescription: 'Staking Issues',
        instruction: "Click here for staking related issues",
        issueIcon: "./images/stake.png"
    },
    {
        issueDescription: 'Swap/Exchange',
        instruction: "Click here for swap/Exchange issues",
        issueIcon: "./images/bank.png"
    },
    {
        issueDescription: 'Connect to Dapps',
        instruction: "Click here for Dapp connection related issues",
        issueIcon: "./images/dapp.png"
    },
    {
        issueDescription: 'Missing/Irregular Balance',
        instruction: "Click here for missing/irregular balance issues",
        issueIcon: "./images/issues.png"
    },
    {
        issueDescription: 'Login Issues',
        instruction: "Click here for login related issues",
        issueIcon: "./images/lock.png"
    },
    {
        issueDescription: 'NFT Issues',
        instruction: "Click here for NFT related issues",
        issueIcon: "./images/lock.png"
    },
    {
        issueDescription: 'Login Issues',
        instruction: "Click here for login related issues",
        issueIcon: "./images/lock.png"
    },
    {
        issueDescription: 'Trading Issues',
        instruction: "Click here for trading related issues",
        issueIcon: "./images/chart.png"
    }

]