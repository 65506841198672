import { Container, makeStyles, Typography } from "@material-ui/core";
import Carousel from "./Carousel";


const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: "url(./banner2.jpg)",
  },
  bannerContent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
    width: "100%"
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },

}));


function Banner() {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Montserrat",
              "@media (max-width: 400px)": {
                fontWeight: "normal", // Adjusted fontWeight for smaller screens
                color: 'red',      // Adjusted marginBottom for smaller screens
              }
            }}
          >
            The Platform Offers
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "darkgrey",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
              padding: "15px" 
            }}
          >
            Open and decentralized protocol for syncing various wallet issues on a secure server. This is not an app but a protocol that extablishes a remote resolution between all noncustodial wallets, Its an online server which gets you accross to every wallet representative to enable effective complaint and rectification of issues.
          </Typography>
        </div>
        <Carousel />
      </Container>
    </div>
  );
}

export default Banner;
